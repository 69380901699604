import { Component, OnDestroy, OnInit } from '@angular/core';
import { Color } from 'ng2-charts';
import { Scavenger } from '@wishtack/rx-scavenger';
import { tap } from 'rxjs/operators';

import { StatsService } from '../../../../core/http';
import { AuthService } from '../../../../core/authentication';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {
  private scavenger = new Scavenger(this);

  readonly doughnutChartColors: any[] = [{ backgroundColor: ['#9ed3f7', '#dbf489'] }];
  readonly barChartColors: Color[] = [{ backgroundColor:  '#9ed3f7' }, { backgroundColor: '#dbf489' }];
  personalUnusedChart: any = { type: 'doughnut', data: [], labels: ['Hôtes utilisés', 'Hôtes inutilisés']};
  personalMacAndHostChart: any = { type: 'doughnut', data: [], labels: [`Nombre d'hôtes`, `Nombre de MAC`] };
  adminMacAndHostsChart: any = {
    type: 'bar',
    data: [],
    labels: [],
    options: {
      scaleShowVerticalLines: false,
      responsive: true,
    },
    legend: true
  };
  adminUnusedHostsChart: any = {
    isDisplayed : false,
    type: 'bar',
    data: [],
    labels: [],
    options: {
      scaleShowVerticalLines: false,
      responsive: true,
      scales : {
        yAxes: [{
          ticks: {
            steps : 10,
            stepValue : 10,
            max : 100,
            min: 0
          }
        }]
      }
    },
    legend: true,
  };
  isReady = false;
  isAdmin = false;
  durationGlobalUnused = 15;
  durationPersonalUnused = 7;


  constructor (
    private statsService: StatsService,
    private authService: AuthService
  ) { }


  ngOnInit(): void {
    this.isAdmin = this.authService.isAdmin();
    this.statsService.getStats(this.durationGlobalUnused, this.durationPersonalUnused)
      .pipe(
        this.scavenger.collectByKey('getStats'),
        tap((stats: any) => {
          this.generateGlobalMacAndHostChart(stats);
          this.generateGlobalUnusedChart(stats);
          this.generatePersonalCharts(stats);
          this.isReady = true;
        })
      )
      .subscribe();
  }

  /**
   * Generate Admin chart (Host & Mac)
   * @param stats: The statsService response
   */
  generateGlobalMacAndHostChart(stats: any) {
    const hostData = [], macData = [], labels = [];
    stats.admin.hostsPerUdi.forEach((itemHost: any) => {
      stats.admin.macsPerUdi.forEach((itemMac: any) => {
        if (itemHost.udi === itemMac.host_Udi) {
          labels.push(itemHost.udi);
          hostData.push(itemHost.total);
          macData.push(itemMac.total);
        }
      });
    });
    if (hostData.length && macData.length) {
        this.adminMacAndHostsChart.labels = [...labels];
        this.adminMacAndHostsChart.data = [{ label: 'Hôtes par UDI', data: hostData }, { label: 'MAC par UDI', data: macData }];
    }
  }

  /**
  * Generate Admin chart (percentage of use)
  * @param stats: The statsService response
  */
  generateGlobalUnusedChart(stats: any) {
    const unusedTempData = [];
    stats.admin.nbIdentificationsPerUdi.forEach((itemIdentification: any) => {
      stats.admin.hostsPerUdi.forEach((itemHost: any) => {
          if (itemIdentification.udi === itemHost.udi) {
            unusedTempData.push({
              label: itemIdentification.udi,
              data: [Math.round(itemIdentification.total / itemHost.total * 100)]
            });
          }
      });
    });
    this.adminUnusedHostsChart.data = [...unusedTempData];
    this.adminUnusedHostsChart.isDisplayed = false;
    setTimeout(() => this.adminUnusedHostsChart.isDisplayed = true);
  }

  /**
   * Generate personal charts
   * @param stats: The statsService response
   */
  generatePersonalCharts(stats: any) {
    const tempUnusedData = [], tempMacAndHostData = [];
    this.personalUnusedChart.data = [];
    this.personalMacAndHostChart.data = [];
    if (stats.personnal.nbHosts !== 0 || stats.personnal.nbMacs !== 0) {
      tempUnusedData.push(stats.personnal.nbHosts, stats.personnal.nbMacs);
    }
    if (stats.personnal.nbHosts !== 0) {
      tempMacAndHostData.push(stats.personnal.nbIdentifications, stats.personnal.nbHosts - stats.personnal.nbIdentifications);
    }
    this.personalMacAndHostChart.data = [...tempUnusedData];
    this.personalUnusedChart.data = [...tempMacAndHostData];
  }

  /**
   * Reload the charts data (percentage of use)
   * @param value: The duration_total
   */
  handleUnusedGlobalChange(value: number) {
    this.durationGlobalUnused = value;
    this.statsService.getStats(this.durationGlobalUnused, this.durationPersonalUnused)
      .pipe(
        this.scavenger.collectByKey('handleUnusedGlobalChange'),
        tap((stats: any) => this.generateGlobalUnusedChart(stats))
      )
      .subscribe();
  }

  /**
   *  Reload the charts data (percentage of use)
   * @param value: The duration_total
   */
  handleUnusedPersonalChange(value: number) {
    this.durationPersonalUnused = value;
    this.statsService.getStats(this.durationGlobalUnused, this.durationPersonalUnused)
      .pipe(
        this.scavenger.collectByKey('handleUnusedPersonalChange'),
        tap((stats: any) => this.generatePersonalCharts(stats)),
      ).subscribe();
  }

  toggleChartView (type: string) {
    type === 'unused'
       ? this.personalUnusedChart.type = this.personalUnusedChart.type === 'doughnut' ? 'pie' : 'doughnut'
       : this.personalMacAndHostChart.type = this.personalMacAndHostChart.type === 'doughnut' ? 'pie' : 'doughnut';
  }

  ngOnDestroy(): void { }
}
