import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';


import { AlertModule } from 'ngx-bootstrap/alert';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { NgxSpinnerModule } from 'ngx-spinner';
import { FlashMessagesModule } from 'angular2-flash-messages';
import { ChartsModule } from 'ng2-charts/ng2-charts';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { RichTextEditorModule } from '@syncfusion/ej2-angular-richtexteditor';
import {NgxExtendedPdfViewerModule} from 'ngx-extended-pdf-viewer';

import { CustomPreloading } from './core/config';
import { CasInterceptor, DebugInterceptor } from './core/interceptors';
import { GuardComponent } from './core/guard';
import { AppComponent } from './app.component';
import { AppRoutingModule} from './app-routing.module';
import { HomeComponent } from './views/pages/secure/home/home.component';
import { HelpComponent } from './views/pages/secure/help/help.component';
import { Page500Component } from './views/pages/errors/page500/page500.component';
import { Page403Component } from './views/pages/errors/page403/page403.component';
import { SecureComponent } from './views/layouts/secure/secure.component';
import { Page404Component } from './views/pages/errors/page404/page404.component';
import { Page503Component } from './views/pages/errors/page503/page503.component';



@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HelpComponent,
    Page500Component,
    Page403Component,
    SecureComponent,
    Page404Component,
    Page503Component
  ],
  imports: [
    ChartsModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserModule,
    ReactiveFormsModule,
    FormsModule,
    NgxSpinnerModule,
    RichTextEditorModule,
    NgxExtendedPdfViewerModule,
    FlashMessagesModule.forRoot(),
    AlertModule.forRoot(),
    ButtonsModule.forRoot(),
    CollapseModule.forRoot(),
    BsDropdownModule.forRoot(),
    PaginationModule.forRoot(),
    TooltipModule.forRoot(),
    AccordionModule.forRoot(),
  ],
  providers: [
    GuardComponent,
    CustomPreloading,
    { provide: HTTP_INTERCEPTORS, useClass: CasInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: DebugInterceptor,  multi: true }
  ],
  bootstrap: [ AppComponent ]
})

export class AppModule { }
