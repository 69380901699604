import { Group, Mac, Person, Manager } from '.';

export class Host {
  id: number;
  name: string;
  description: string;
  group: Group;
  udi: string;
  managers: Manager[] = [];
  macAddresses: Mac[] = [];
  persons: Person[] = [];
  created: Date;
  modified: Date;
  createdBy: string;
  modifiedBy: string;
  lastIdentification: Date;
}
