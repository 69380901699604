import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
import { Group, ImpersonateUser, User} from '../../shared/models';


@Injectable({
  providedIn: 'root'
})
export class UserService {
  private url = `${environment.server}/api/v1/user`;
  private httpOptions = {
    headers: new HttpHeaders().set('Content-Type', 'application/json').set('isListGroup', '')
  };

  constructor(
    private http: HttpClient
  ) { }

  getUser(): Observable<User> {
    return this.http.get(this.url, this.httpOptions) as Observable<User>;
  }

  getImpersonateUser(): Observable<ImpersonateUser> {
    return this.http.get(`${this.url}/impersonate`, this.httpOptions) as Observable<ImpersonateUser>;
  }

  impersonateUser(userId): Observable<ImpersonateUser> {
    return this.http.post(`${this.url}/impersonate`, {'impersonate_user': userId}, this.httpOptions) as Observable<ImpersonateUser>;
  }

  logoutImpersonate(): Observable<User> {
    return this.http.get(`${this.url}/impersonate_logout`, this.httpOptions) as Observable<User>;
  }

  findAllUdi(): Observable<any[]> {
    return this.http.get(`${this.url}/udi`, this.httpOptions) as Observable<any[]>;
  }

  findEnabledUdi(group: Group | number): Observable<any[]> {
    const id = typeof group === 'number' ? group : group.id;
    return this.http.get(`${this.url}/udi/enabled/groups/${id}`, this.httpOptions) as Observable<any[]>;
  }

  findUdiForManager(flag: string): Observable<any[]> {
    this.httpOptions= {
      headers: new HttpHeaders().set('Content-Type', 'application/json').set('isListGroup', flag)
    };
    return this.http.get(`${this.url}/udi`, this.httpOptions) as Observable<any[]>;
  }
}
