import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable,  throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { environment } from '../../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class ImportService {
  private url = `${environment.server}/api/v1/tools/import` ;
  private httpOptions = {
    headers: new HttpHeaders().set('Content-Type', 'application/json')
  };

  constructor(
    private http: HttpClient,
    private spinner: NgxSpinnerService
  ) { }

  import(jsonHosts): Observable<any> {
    this.spinner.show();
    return this.http.post( this.url, jsonHosts, this.httpOptions)
      .pipe(
        tap(() =>  this.spinner.hide()),
        catchError((err: HttpErrorResponse) => this.handleError(err))
      );
  }

  private handleError(err): Observable<any> {
    this.spinner.hide();
    return throwError(err);
  }
}
