import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { Group, Page } from '../../shared/models';


@Injectable({
  providedIn: 'root'
})
export class GroupService {
  private url = `${environment.server}/api/v1/groups`;
  private httpOptions = {
    headers: new HttpHeaders().set('Content-Type', 'application/json')
  };

  constructor(
    private http: HttpClient,
    private spinner: NgxSpinnerService
  ) { }

  getGroup(id: number): Observable<Group> {
    return this.http.get<Group>(`${this.url}/${id}`, this.httpOptions) as Observable<Group>;
  }

  getGroups(): Observable<Group[]> {
    return this.http.get(this.url, this.httpOptions) as Observable<Group[]>;
  }

  getPagedGroups(page: Page, filter: string, order: number, orderField: string):
    Observable<{ groups: Group[], totalElements: number, count: any[]}> {

    let params = new HttpParams()
      .set('filter', filter.trim())
      .set('pageSize', String(page.size))
      .set('pageNumber', String(page.pageNumber));

    if ((order === 1 || order === -1) && orderField && orderField.trim() !== '') {
      params = order === -1
         ? params.append('orderBy', `-${orderField}`)
         : params.append('orderBy', orderField);
    }

    return this.http.get(this.url, {...this.httpOptions, params}) as Observable<{ groups: Group[], totalElements: number, count: any[]}>;
  }

  addGroup(group: Group): Observable<Group> {
    this.spinner.show();
    return this.http.post(this.url, group, this.httpOptions)
      .pipe(
        tap(() => this.spinner.hide()),
        catchError((err: HttpErrorResponse) => this.handleError(err))
      ) as Observable<Group>;
  }

  editGroup(group: Group): Observable<Group> {
    const url = `${this.url}/${group.id}`;
    this.spinner.show();
    return this.http.put(url, group, this.httpOptions)
      .pipe(
        tap(() => this.spinner.hide()),
        catchError((err: HttpErrorResponse) => this.handleError(err))
      ) as Observable<Group>;
  }

  deleteGroup (group: Group | number): Observable<any> {
    this.spinner.show();
    const id = typeof group === 'number' ? group : group.id;
    return this.http.delete(`${this.url}/${id}`, this.httpOptions)
      .pipe(
        tap(() => this.spinner.hide()),
        catchError((err: HttpErrorResponse) => this.handleError(err))
      );
  }

  private handleError(err): Observable<any> {
    this.spinner.hide();
    return throwError(err);
  }
}
