import { Person } from './person.model';

export class LdapPerson extends Person {
  ulgId: string;
  firstName: string;
  lastName: string;
  gender: string;
  email?: string;
  ulgMasterId?: string;
  service?: string;
}

