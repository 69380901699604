import { Injectable } from '@angular/core';
import { PreloadingStrategy, Route } from '@angular/router';
import { Observable, of, timer } from 'rxjs';
import { flatMap } from 'rxjs/operators';

@Injectable()
export class CustomPreloading implements PreloadingStrategy {
  /**
   * Implement the Angular PreloadingStrategy
   * Preload component according to multiples parameters
   * @param route: The current URL (containing the delay & boolean),
   * @param load: The function used to load the component
   */
  preload(route: Route, load: Function): Observable<any> {
    const loadRoute = (delay) => delay
      ? timer(delay).pipe(flatMap(() => load()))
      : load();

    return route.data && route.data.preload
      ? loadRoute(route.data.delay)
      : of(null);
  }
}
