import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { Host, Page } from '../../shared/models';


@Injectable({
  providedIn: 'root'
})
export class HostService {
  private url = `${environment.server}/api/v1/hosts`;
  private httpOptions = {
    headers: new HttpHeaders().set('Content-Type', 'application/json').set('isListManager', '')
  };

  constructor(
    private http: HttpClient,
    private spinner: NgxSpinnerService
  ) { }

  getHost(id: number): Observable<Host> {
    return this.http.get(`${this.url}/${id}`, this.httpOptions) as Observable<Host>;
  }

  /*getHostForManager(id: number, flag: string): Observable<Host> {
    this.httpOptions = {
      headers: new HttpHeaders().set('Content-Type', 'application/json').set('isListManager', flag)
    };
    return this.http.get(`${this.url}/${id}`, this.httpOptions) as Observable<Host>;
  }*/

  getPagedHosts(page: Page, search = '', order = 0 , orderField= '', inactivityMonths = 0):
    Observable<{ hosts: Host[], totalElements: number}> {

    let params = new HttpParams()
      .set('filter', search.trim())
      .set('pageSize', String(page.size))
      .set('pageNumber', String(page.pageNumber))
      .set('isMinified', 'true');

    if ((order === 1 || order === -1) && orderField && orderField.trim() !== '') {
      params = order === -1
        ? params.append('orderBy', `-${orderField}`)
        : params.append('orderBy', orderField);
    }
    if (inactivityMonths > 0) {
      params = params.append('inactivityMonths', String(inactivityMonths));
    }

    return this.http.get(this.url, { ...this.httpOptions, params}) as Observable<{ hosts: Host[], totalElements: number}>;
  }

  addHost(host: Host): Observable<Host> {
    this.spinner.show();
    return this.http.post(this.url, host, this.httpOptions)
      .pipe(
        tap(() => this.spinner.hide()),
        catchError((err: HttpErrorResponse) => this.handleError(err))
      ) as Observable<Host>;
  }

  editHost(host: Host): Observable<Host>  {
    this.spinner.show();
    return this.http.put(`${this.url}/${host.id}`, host, this.httpOptions)
      .pipe(
        tap(() =>  this.spinner.hide()),
        catchError((err: HttpErrorResponse) => this.handleError(err))
      ) as Observable<Host>;
  }

  deleteHost (host: Host | number): Observable<any> {
    const id = typeof host === 'number' ? host : host.id;
    this.spinner.show();
    return this.http.delete(`${this.url}/${id}`, this.httpOptions)
      .pipe(
        tap(() => this.spinner.hide()),
        catchError((err: HttpErrorResponse) => this.handleError(err))
      );
  }

  private handleError(err): Observable<any> {
    this.spinner.hide();
    return throwError(err);
  }
}
