import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FlashMessagesService } from 'angular2-flash-messages';

import { CustomError } from '../../shared/models';


@Injectable({
  providedIn: 'root'
})
export class HelperService {

  constructor(
    private flashMessageService: FlashMessagesService,
  ) { }

  /**
   * Display a flash message
   * @params message, type, important, timeout
   */
  sendFlash (message: string, type: string = 'success', important: boolean = false, timeout: number = 2500) {
    important
      ? this.flashMessageService.show(message, {cssClass: `alert-${type}`, closeOnClick: true, showCloseBtn: true, timeout: 60000})
      : this.flashMessageService.show(message, {cssClass: `alert-${type}`, timeout: timeout});
  }

  /**
   * Handle httpErrors for http services
   * @param err The HttpErrorResponse
   * @return returns an array with a title and an array of messages (like Django validations errors)
   */
  handleHttpErrors (err: HttpErrorResponse): CustomError {
    const httpError = new CustomError();
    Object.keys(err.error).forEach((key, index) => {
      httpError.titles.push(this.capitalize(key));
      const messages: string[] =  [];
      err.error[key].map((value: string) => messages.push(value));
      httpError.messages[index] = messages ;
    });
    return httpError;
  }

  capitalize (string: string): string {
    return string == null  || string.trim() === '' ? '' : string.charAt(0).toUpperCase() + string.slice(1);
  }
}



