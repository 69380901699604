export class Mac {
  id: number;
  mac: string;
  description: string;
  type: string;
  vendor: string;
  lastIdentification: Date;
  device: string;
  interface: string;
  created: Date;
  modified: Date;
  iseId: string;

  constructor() {
    this.mac = '';
    this.description = '';
  }
}
