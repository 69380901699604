import { Router, RoutesRecognized } from '@angular/router';
import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { filter, pairwise } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class RedirectService {
  private previousUrl = '';

  constructor(
    private router: Router,
    private location: Location
  ) {
    this.router.events
      .pipe(
        filter((e: any) => e instanceof RoutesRecognized),
        pairwise()
      )
      .subscribe((e: any) => this.previousUrl = e[0].urlAfterRedirects);
  }

  isRefreshed (): boolean {
    return this.previousUrl === '';
  }

  /** Same as location.back(), but redirect to a custom path if the navigator is refresh */
  redirect(url: string = '/'): void {
    if (this.isRefreshed()) {
      this.router.navigate([url]);
    }
    this.location.back();
  }
}
