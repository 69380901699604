import { ApiKey } from '.';

export class User {
 ulgId: string;
 udi: string;
 roles: string[] = [];
 firstName: string;
 lastName: string;
 apiKeys: ApiKey[] = [];
 isImpersonate?: boolean;
 realUser?: User;
}

export class ImpersonateUser {
  ulgId: string;
  udi: string;
  roles: string[] = [];
  firstName: string;
  lastName: string;
  apiKeys: ApiKey[] = [];
  realUserInfo: User;
}
