import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { User, ApiKey } from '../../shared/models';
import { UserService } from '../http';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private user: User;

  constructor(
    private userService: UserService
  ) { }

  get currentUser(): User {
    return { ...this.user };
  }

  get apiKey(): ApiKey[] {
    return this.user.apiKeys;
  }

  deleteApiKey(): void {
    this.user.apiKeys = null;
  }

  isAdmin(): boolean {
    return this.user.roles.includes('admin');
  }

  isReadAll(): boolean {
    return this.user.roles.includes('read_all');
  }

  isSegi(): boolean {
    return this.user.roles.includes('segi');
  }

  /** Handle user roles ('user', 'segi' or 'admin') */
  isGranted(role: string = 'user', forceCall = false): Observable<boolean> {
    return (!this.user || forceCall)
      ? this.userService.getUser()
        .pipe(
          tap(user => this.user = user),
          map(() =>  this.user.roles && Array.isArray(this.user.roles) && this.user.roles.includes(role))
        )
      : of (this.user.roles && Array.isArray(this.user.roles) && this.user.roles.includes(role));
  }
}
