const messages = {
  mailer: 'devops.segi@uliege.be',
  server: `Une erreur s'est produite, merci de réessayer ulterieurement.`,
  notFound: `La ressource demandée est introuvable.`,
  badRequest: `La requête est erronée, merci de verifier tous les champs.`,
  unauthorized: 'Vous ne pouvez pas effectuer cette action.'
};


export default {
  HTTP_500_SERVER_ERROR: messages.server,
  HTTP_404_NOT_FOUND: messages.notFound,
  HTTP_400_BAD_REQUEST: messages.badRequest,

  getErrors(status: number): string {
    switch (status) {
      case 400:
        return messages.badRequest;
      case 403:
        return messages.unauthorized;
      case 404:
        return messages.notFound;
      default:
        return messages.server;
    }
  }
};
