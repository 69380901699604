import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { ActivatedRouteSnapshot } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { AuthService } from '../authentication';


@Injectable()
export class GuardComponent implements CanActivate {

  constructor(
    private authService: AuthService,
    private router: Router
  ) { }

  /**
   * Handle user permission for the current Url
   * @param route The current url under the GuardComponent
   * @returns returns a Observable<boolean> if the user is granted ('admin' || 'user') or redirect to error page
   */
   canActivate(route: ActivatedRouteSnapshot): Observable<boolean> | Promise<boolean> | boolean  {
     const requiredRole = route.data.role as string;
     const forceCall = route.data.force as boolean; // Force the userService call
     return this.authService.isGranted(requiredRole, forceCall)
       .pipe(
         tap((access: boolean) => !access ? this.router.navigate(['/403']) : null),
         catchError((err: HttpErrorResponse) =>  this.handleError(err))
       );
  }

  /**
   * Redirect following the HTTP error status (The 401 && 503 are managed by the CasInterceptor)
   * @param error HttpErrorResponse (containing the Http status)
   */
  handleError (error: HttpErrorResponse): Observable<any> {
    if ([403, 500, 503].includes(error.status)) {
      this.router.navigate([`/${error.status}`]);
    }
    return throwError(error);
  }
}
