import { Udi } from './udi.model';

export class Group {
  id: number;
  name: string;
  description: string;
  vlan: number;
  iseId: string;
  created: Date;
  modified: Date;
  createdBy: string;
  modifiedBy: string;
  udiList: Udi[] = [];
}
