import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { GuardComponent } from './core/guard';
import { CustomPreloading } from './core/config';
import { HomeComponent } from './views/pages/secure/home/home.component';
import { HelpComponent } from './views/pages/secure/help/help.component';
import { Page403Component } from './views/pages/errors/page403/page403.component';
import { Page500Component } from './views/pages/errors/page500/page500.component';
import { SecureComponent } from './views/layouts/secure/secure.component';
import { Page404Component } from './views/pages/errors/page404/page404.component';
import { Page503Component } from './views/pages/errors/page503/page503.component';


const routes: Routes = [
  { path: '',  component: SecureComponent, canActivate: [GuardComponent], children : [
    { path: '', component: HomeComponent, pathMatch: 'full'},
    { path: 'help', component: HelpComponent },
    { path: 'host', loadChildren: () => import('./views/pages/secure/host/host.module')
        .then(m => m.HostModule), data: { preload: true, delay: 2500 }},
    { path: 'group', loadChildren: () => import('./views/pages/secure/group/group.module')
        .then(m => m.GroupModule), data: { preload: true, delay: 2500 }},
      { path: 'ioc', loadChildren: () => import('./views/pages/secure/ioc/ioc.module')
          .then(m => m.IocModule), data: { preload: true, delay: 2500 }},
    { path: 'tools', loadChildren: () => import('./views/pages/secure/tools/tools.module')
        .then(m => m.ToolsModule), data: { preload: true, delay: 2500 }},
    { path: 'admin', loadChildren: () => import('./views/pages/secure/admin/admin.module')
        .then(m => m.AdminModule), data: { preload: false, delay: false, role: 'admin'}, canActivate: [GuardComponent] }
  ]},
  { path: '403', component: Page403Component },
  { path: '500', component: Page500Component },
  { path: '503', component: Page503Component },
  { path: '**', component: Page404Component }
];

@NgModule({
  imports: [ RouterModule.forRoot(routes, { preloadingStrategy: CustomPreloading}) ],
  exports: [ RouterModule ]
})

export class AppRoutingModule { }
