import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
import { LdapPerson } from '../../shared/models';


@Injectable({
  providedIn: 'root'
})
export class LdapService {
  private url = `${environment.devops_tools_server}/api/v1/ldap` ;
  private httpOptions = {
    headers: new HttpHeaders().set('Content-Type', 'application/json')
  };

  constructor(
    private http: HttpClient,
  ) { }

  findInLdap(field, filter): Observable<LdapPerson[]> {
    const params = new HttpParams()
      .set('filter', filter.trim())
      .set('field', field.trim());

    return this.http.get(this.url, { ...this.httpOptions, params}) as Observable<LdapPerson[]>;
  }
}
