import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpErrorResponse} from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, throwError } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';

import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ToolsService {
  private httpOptions = {
    headers: new HttpHeaders().set('Content-Type', 'application/json'),
  };
  private url = `${environment.server}/api/v1/tools`;

  constructor(
    private http: HttpClient,
    private spinner: NgxSpinnerService
  ) {
  }

  findMac(macAddress: string): Observable<any> {
    this.spinner.show();
    return this.http.post(`${this.url}/find_mac_live`, {mac: macAddress}, this.httpOptions)
      .pipe(
        tap(() => this.spinner.hide()),
        catchError((err: HttpErrorResponse) => this.handleError(err))
      );
  }

  configure(params: any): Observable<any> {
    this.spinner.show();
    return this.http.post(`${this.url}/configure_device`, params, this.httpOptions)
      .pipe(
        tap(() => this.spinner.hide()),
        catchError((err: HttpErrorResponse) => this.handleError(err))
      );
  }

  private handleError(err): Observable<any> {
    this.spinner.hide();
    return throwError(err);
  }
}
