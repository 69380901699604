import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class StatsService {
  private url = `${environment.server}/api/v1/stats`;
  private httpOptions = {
    headers: new HttpHeaders().set('Content-Type', 'application/json')
  };

  constructor(
    private http: HttpClient,
  ) { }

  getStats(durationUnusedGlobal: number = 7, durationUnusedPersonal: number = 7): Observable<any> {
    const params = new HttpParams()
      .set('duration_unused_global', String(durationUnusedGlobal))
      .set('duration_unused_personnal', String(durationUnusedPersonal));

    return this.http.get<any>(this.url,  { ...this.httpOptions, params });
  }
}

