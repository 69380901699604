import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { environment } from '../../../environments/environment';

/**
 * Works on dev environment, log request and response foreach Ajax calls
 */
@Injectable()
export class DebugInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (environment.production) {
      return next.handle(req);
    }

    console.log('Request :', req, req.body || '');
    return next.handle(req)
      .pipe(
        tap(e => e instanceof HttpResponse ? console.log('Response', e, e.body || '') : null),
        catchError((err: HttpErrorResponse) => {
          console.log('Erreur :', err);
          return throwError(err);
        })
      );
  }
}

