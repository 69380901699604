import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
import { ApiKey } from '../../shared/models';


@Injectable({
  providedIn: 'root'
})
export class ApiKeyService {
  private url = `${environment.server}/api/v1/token`;
  private httpOptions = {
    headers: new HttpHeaders().set('Content-Type', 'application/json')
  };

  constructor(
    private http: HttpClient,
  ) { }

  getApiKeys(): Observable<ApiKey[]> {
    return this.http.get<ApiKey[]>(this.url,  this.httpOptions) as Observable<ApiKey[]>;
  }

  createApiKey(apiKey: ApiKey): Observable<ApiKey> {
    return this.http.post(this.url, apiKey, this.httpOptions) as Observable<ApiKey>;
  }

  editApiKey(apiKey: ApiKey): Observable<ApiKey> {
    return this.http.put(`${this.url}/${apiKey.id}`, apiKey, this.httpOptions) as Observable<ApiKey>;
  }

  deletePersonalApiKey(): Observable<any> {
    return this.http.delete(this.url, this.httpOptions);
  }

  deleteApiKey(id: number): Observable<any> {
    return this.http.delete(`${this.url}/${id}`, this.httpOptions);
  }
}

