import {Component, OnDestroy} from '@angular/core';
import { AuthService } from '../../../core/authentication';
import { fadeAnimation } from './animation';
import { User} from '../../../shared/models';
import {catchError, tap} from 'rxjs/operators';
import {HttpErrorResponse} from '@angular/common/http';
import ErrorMessages from '../../../core/helpers/messages';
import {of} from 'rxjs';
import {Router} from '@angular/router';
import {UserService} from '../../../core/http';
import {HelperService} from '../../../core/services';
import {Scavenger} from '@wishtack/rx-scavenger';


@Component({
  selector: 'app-secure',
  templateUrl: './secure.component.html',
  styleUrls: ['./secure.component.scss'],
  animations: [fadeAnimation]
})
export class SecureComponent implements OnDestroy {
  isCollapsed = true;

  private scavenger = new Scavenger(this);

  constructor(
    private authService: AuthService,
    private router: Router,
    private userService: UserService,
    private helperService: HelperService
  ) { }

  get ulgId(): string {
    return this.authService.currentUser.ulgId;
  }

  get isImpersonate(): boolean {
    return this.authService.currentUser.isImpersonate;
  }

  get isAdmin(): boolean {
    return this.authService.isAdmin();
  }

  get isSegi(): boolean {
    return this.authService.isSegi();
  }

  role(user: User = this.authService.currentUser): string {
    if (user.roles.includes('admin')) {
      return 'Admin';
    }
    if (user.roles.includes('segi')) {
      return 'Segi';
    }
    return 'User';
  }

  logoutImpersonate(): void {
    this.userService.logoutImpersonate()
      .pipe(
        this.scavenger.collectByKey('logoutImpersonate'),
        tap(() => {
          this.helperService.sendFlash('Imitation terminée.<br>Le site va se recharger.', 'success');
          this.router.navigate(['/']);
          setTimeout(function () {
            window.location.reload();
          }, 3000);
        }),
        catchError((err: HttpErrorResponse) => {
          let errorMessage = '';
          errorMessage = err.status >= 400 && err.status <= 404
            ? ErrorMessages.getErrors(err.status)
            : ErrorMessages.HTTP_500_SERVER_ERROR;
          this.helperService.sendFlash(errorMessage, 'danger');
          return of(null);
        })
      )
      .subscribe();
  }

  ngOnDestroy(): void {
  }
}
